import { Autocomplete, Box, Button, Menu, TextField } from "@mui/material";
import React, { useRef } from "react";
import { editBoardField } from "../../../utils/ApiCall";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useCustomState } from "../../../utils/useCustomState";

const EditableSelectCountry = ({
  row,
  field,
  handleEditResponseChange,
  options,
  initialValue,
}) => {
  let currentUrl = window.location.pathname;

  const initialCountryNames =
    initialValue
      ?.split(",")
      ?.map((name) => name.trim())
      ?.filter((name) => name !== "") || [];

  const initialSelectedValue = initialCountryNames.length
    ? initialCountryNames
        .map((name) => {
          return options.find((country) => country.label === name);
        })
        .filter(Boolean)
    : [];

  const [state, updateState] = useCustomState({
    selectedValue: initialSelectedValue,
    menu: { anchorEl: null, open: false },
    windowWidth: window.innerWidth,
  });

  const isMobile = state.windowWidth <= 600;
  const cellRef = useRef(null);

  const handleClose = () => {
    updateState({
      menu: {
        ...state.menu,
        open: false,
      },
    });
  };

  const handleClick = (event) => {
    updateState({ menu: { anchorEl: event.currentTarget, open: true } });
  };

  const handleAutocompleteChange = (_, newValue) => {
    updateState({
      selectedValue: Array.isArray(newValue) ? newValue : [],
    });
  };

  const getOptionLabelFunc = (country) => {
    return country?.label;
  };

  const cellStyle = {
    position: "relative",
  };

  const menuStyle = {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    width: 350,
    height: isMobile ? 600 : "auto",
    overflowY: isMobile ? "scroll" : "",
    paddingRight: isMobile ? "20px" : "0px",
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: "49%",
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  };

  const menuProps = {
    anchorEl: state.menu.anchorEl,
    id: "account-menu",
    open: state.menu.open,
    onClose: handleClose,
    PaperProps: {
      elevation: 0,
      sx: {
        ...menuStyle,
        marginLeft: isMobile ? "0px" : "115px",
      },
    },
    transformOrigin: { horizontal: "right", vertical: "top" },
    anchorOrigin: { horizontal: "right", vertical: "bottom" },
  };

  const handleSubmit = () => {
    const countryNames = state.selectedValue
      .map((item) => item.label)
      .join(", ");
    editBoardField(currentUrl, [row.id], field, countryNames);
    handleEditResponseChange(countryNames, row.id, field);

    handleClose();
  };

  return (
    <Box>
      <p
        onClick={handleClick}
        ref={cellRef}
        style={cellStyle}
        className="cellStyle"
      >
        <div
          style={{
            width: "120px",
            padding: "0 10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {(initialValue === null || initialValue === "") && (
            <AddCircleIcon sx={{ color: "var(--blackLow)" }} />
          )}
          {options?.map((el) => {
            if (initialValue === el?.label || initialValue === el?.code) {
              return (
                <React.Fragment key={el.code}>
                  <img
                    src={`https://flagcdn.com/w20/${el.code.toLowerCase()}.png`}
                    style={{ marginRight: "8px" }}
                    alt=""
                  />
                </React.Fragment>
              );
            } else return null;
          })}
          {options?.map((el) => {
            if (
              initialValue?.toLowerCase() === el?.label?.toLowerCase() ||
              initialValue?.toLowerCase() === el?.code?.toLowerCase()
            ) {
              return <p key={el.code}>{el.label}</p>;
            } else return null;
          })}
          {options?.every(
            (el) =>
              initialValue?.toLowerCase() !== el?.label?.toLowerCase() &&
              initialValue?.toLowerCase() !== el?.code?.toLowerCase()
          ) && <span>{initialValue}</span>}
        </div>
      </p>
      <Menu {...menuProps}>
        <Autocomplete
          size="small"
          multiple
          disablePortal
          id="combo-box-demo"
          options={options}
          getOptionLabel={getOptionLabelFunc}
          sx={{ width: "100%" }}
          onChange={handleAutocompleteChange}
          value={state.selectedValue}
          getOptionDisabled={(option) =>
            state.selectedValue.some((country) => country === option.label)
          }
          renderOption={(props, option) => (
            <Box
              component="li"
              key={option.code}
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option?.code?.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option?.code?.toLowerCase()}.png 2x`}
                alt=""
              />
              {option.label}
            </Box>
          )}
          renderInput={(params) => (
            <TextField {...params} label="Countries" variant="standard" />
          )}
        />
        <div
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <Button sx={{ marginTop: "20px" }} onClick={handleSubmit}>
            Guardar
          </Button>
        </div>
      </Menu>
    </Box>
  );
};

export default EditableSelectCountry;
