import React, { useRef } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Menu,
  TextField,
  Tooltip,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { editBoardField } from "../../../utils/ApiCall";
import { useCustomState } from "../../../utils/useCustomState";

const EditableSelect = ({
  row,
  field,
  handleEditResponseChange,
  options,
  spanText,
  initialValue,
  label,
}) => {
  const [state, updateState] = useCustomState({
    selectedValue: initialValue || null,
    saveButtonLabel: "Guardar",
    disableSave: false,
    open: false,
    anchorEl: null,
  });

  const cellRef = useRef(null);
  const isMobile = window.innerWidth <= 600;

  const handleClick = (event) => {
    updateState({ open: true, anchorEl: event.currentTarget });
  };

  const handleClose = () => {
    updateState({ open: false, anchorEl: null });
  };

  const handleAutocompleteChange = (_, newValue) => {
    updateState({ selectedValue: newValue });
  };

  const handleSubmit = async () => {
    updateState({ saveButtonLabel: "Guardando", disableSave: true });

    try {
      await editBoardField(
        window.location.pathname,
        [row.id],
        field,
        state.selectedValue
      );
      handleEditResponseChange(state.selectedValue, row.id, field);
    } finally {
      updateState({ saveButtonLabel: "Guardar", disableSave: false });

      handleClose();
    }
  };

  const menuStyle = {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    width: 350,
    height: isMobile ? 600 : "auto",
    overflowY: isMobile ? "scroll" : "",
    paddingRight: isMobile ? "20px" : "0px",
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: "49%",
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  };

  return (
    <Box>
      <p onClick={handleClick} ref={cellRef} className="cellStyle">
        <div
          style={{
            width: "max-content",
            padding: "0 10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {spanText ? (
            <Tooltip title={spanText}>
              <span className="cell-data">{spanText}</span>
            </Tooltip>
          ) : (
            <AddCircleIcon sx={{ color: "var(--blackLow)" }} />
          )}
        </div>
      </p>
      <Menu
        anchorEl={state.anchorEl}
        open={state.open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            ...menuStyle,
            marginLeft: isMobile ? "0px" : "115px",
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Autocomplete
          size="small"
          disablePortal
          options={options}
          getOptionLabel={(opt) => opt}
          value={state.selectedValue}
          onChange={handleAutocompleteChange}
          renderInput={(params) => (
            <TextField {...params} label={label} variant="standard" />
          )}
          sx={{ width: "100%" }}
        />
        <div
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            sx={{ marginTop: "20px" }}
            onClick={handleSubmit}
            disabled={state.disableSave}
          >
            {state.saveButtonLabel}
          </Button>
        </div>
      </Menu>
    </Box>
  );
};

export default EditableSelect;
