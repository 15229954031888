import * as React from "react";
import {
  DataGridPro,
  useGridApiContext,
  useGridSelector,
  gridFilteredDescendantCountLookupSelector,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  TextField,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { fetchData } from "../../../../utils/ApiCall";
import { useEffect } from "react";
import CustomToolBar from "../../../../components/global/CustomToolBar";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useCustomState } from "../../../../utils/useCustomState";

export const isNavigationKey = (key) =>
  key === "Home" ||
  key === "End" ||
  key.indexOf("Arrow") === 0 ||
  key.indexOf("Page") === 0 ||
  key === " ";

function CustomGridTreeDataGroupingCell(props) {
  const { id, field, rowNode } = props;
  const apiRef = useGridApiContext();
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector
  );
  const filteredDescendantCount =
    filteredDescendantCountLookup[rowNode.id] ?? 0;

  const handleClick = (event) => {
    if (rowNode.type !== "group") {
      return;
    }

    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  return (
    <div onClick={handleClick}>
      <Box sx={{ ml: rowNode.depth * 4 }}>
        <div>
          {filteredDescendantCount > 0 ? (
            <span
              tabIndex={-1}
              size="small"
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              <ArrowForwardIosIcon
                fontSize="small"
                sx={{ marginRight: "6px" }}
              />
              {props?.value}
            </span>
          ) : (
            <span
              tabIndex={-1}
              size="small"
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              {props?.value}
            </span>
          )}
        </div>
      </Box>
    </div>
  );
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#0971f1",
      darker: "#053e85",
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
  },
});

const getTodaysDay = () => {
  const date = new Date().toISOString();
  const first = "01";
  const firstOfMonth = date.slice(0, 8).concat(first);
  const today = date.slice(0, 10);

  return { firstOfMonth, today };
};

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export default function TreeDataCustomGroupingColumn() {
  const apiRef = useGridApiRef();
  const apiService = `${backendUrl}/api/offer-service`;
  const dates = getTodaysDay();

  const [state, updateState] = useCustomState({
    offers: [],
    searchText: "",
    affiliates: [],
    isSearching: false,
    enableButton: false,
    isLoadingRows: false,
    searchByAffiliateID: null,
    newArray: [],
    filterOn: false,
    isListOpen: false,
    isTextFieldClicked: false,
    affiliateID: "",
    dateFrom: dates.firstOfMonth,
    dateTo: dates.today,
  });

  const searchOffers = async (query, searchParam) => {
    const url = `${apiService}/autocompleteAffiliates?${searchParam}=${query}`;
    const resOffers = await fetchData(url);

    updateState({ affiliates: resOffers });
  };

  useEffect(() => {
    if (state.searchText && !state.affiliateID) {
      if (state.searchByAffiliateID) {
        searchOffers(state.searchText, "affiseID");
      } else {
        searchOffers(state.searchText, "title");
      }
      updateState({ isSearching: true, isListOpen: true });
    } else {
      updateState({ affiliates: [], isSearching: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.searchText,
    state.searchByAffiliateID,
    state.isTextFieldClicked,
    state.isSearching,
  ]);

  const handleSearchTextChange = (e) => {
    updateState({
      searchText: e.target.value,
      searchByAffiliateID: !isNaN(e.target.value),
      isTextFieldClicked: false,
    });
  };

  const handleLiClick = async (value) => {
    if (value) {
      updateState({
        searchText: value.title,
        isTextFieldClicked: false,
        affiliateID: value.affiseID,
        isListOpen: false,
        enableButton: true,
        isSearching: false,
      });
    }
  };

  const handleSubmit = async () => {
    if (!isNaN(state.affiliateID)) {
      updateState({ enableButton: false, offers: [], isLoadingRows: true });

      const formattedDateFrom = state.dateFrom
        ? dayjs(state.dateFrom).format("YYYY-MM-DD")
        : "";
      const formattedDateTo = state.dateTo
        ? dayjs(state.dateTo).format("YYYY-MM-DD")
        : "";

      const seguimientoUrl = `${apiService}/campaignTrackingByAffiliate?affiseID=${state.affiliateID}&dateFrom=${formattedDateFrom}&dateTo=${formattedDateTo}`;

      const dates = getTodaysDay();

      updateState({ dateTo: dates.today, dateFrom: dates.firstOfMonth });

      const seguimientoData = await fetchData(seguimientoUrl);

      updateState({
        isLoadingRows: false,
        offers: seguimientoData,
        affiliates: [],
        searchText: "",
      });
    }
  };
  const columns = [
    { field: "offerID", headerName: "Offer ID", width: 80 },
    { field: "affiliateID", headerName: "Affiliate ID", width: 80 },
    { field: "affiliateName", headerName: "Affiliate Name", width: 180 },
    { field: "manager", headerName: "Affiliate Manager", width: 140 },
    {
      field: "hierarchy",
      headerName: "Dia",
      width: 60,
      valueGetter: (params) => {
        const hierarchy = params.row.hierarchy;
        const rowNode = params.rowNode;

        if (String(params.row.id) === String(totalRow.id)) {
          return "TOTAL";
        }

        if (rowNode.depth === 0) {
          return "";
        }

        if (hierarchy.length === 2) {
          const day = hierarchy[hierarchy.length - 1];
          return `${day.toString()}`;
        } else {
          return hierarchy[0];
        }
      },
    },
    {
      field: "clicks",
      headerName: "Clicks",
      width: 80,
      valueGetter: (params) => {
        const rowNode = params.rowNode;

        if (rowNode.depth === 0) {
          return params.row.totalClicks;
        } else {
          return params.row.clicks;
        }
      },
    },
    {
      field: "pending",
      headerName: "Pending",
      width: 80,
      valueGetter: (params) => {
        const rowNode = params.rowNode;

        if (rowNode.depth === 0) {
          return params.row.totalPending;
        } else {
          return params.row.pending;
        }
      },
    },
    {
      field: "declined",
      headerName: "Declined",
      width: 80,
      valueGetter: (params) => {
        const rowNode = params.rowNode;

        if (rowNode.depth === 0) {
          return params.row.totalDecline;
        } else {
          return params.row.declined;
        }
      },
    },
    {
      field: "cr",
      headerName: "Cr",
      width: 70,

      valueGetter: (params) => {
        const rowNode = params.rowNode;

        if (rowNode.depth === 0) {
          return roundDecimal(params.row.totalCr) + "%";
        } else {
          return roundDecimal(params.row.cr) + "%";
        }
      },
    },
    {
      field: "capAffiliate",
      headerName: "Cap Affiliate",
      width: 85,
      valueGetter: (params) => params.row.capAffiliate ?? null,
    },
    { field: "capTotal", headerName: "Cap Offer", width: 85 },
    {
      field: "revenue",
      headerName: "Revenue",
      width: 80,
      valueGetter: (params) => {
        const rowNode = params.rowNode;
        let revenue =
          rowNode.depth === 0 ? params.row.totalRevenue : params.row.revenue;

        revenue = parseInt(revenue).toFixed(0);

        return `$${revenue}`;
      },
    },
    {
      field: "payouts",
      headerName: "Payout",
      width: 80,
      valueGetter: (params) => {
        const rowNode = params.rowNode;

        if (rowNode.depth === 0) {
          return `$${params.row.totalPayout}`;
        } else {
          return `$${params.row.payouts}`;
        }
      },
    },
    {
      field: "profit",
      headerName: "Profit",
      width: 80,
      valueGetter: (params) => {
        const rowNode = params.rowNode;
        let profit =
          rowNode.depth === 0 ? params.row.totalProfit : params.row.profit;

        profit = parseInt(profit).toFixed(0);

        return `$${profit}`;
      },
    },
    {
      field: "payoutAffiliate",
      headerName: "Payout Affiliate",
      width: 120,
      valueGetter: (params) => {
        const rowNode = params.rowNode;

        if (rowNode.depth === 0) {
          return `$${roundDecimal(params.row.totalPayoutAffiliate)}`;
        } else {
          return `$${roundDecimal(params.row.payoutAffiliate)}`;
        }
      },
    },
  ];

  const getTreeDataPath = (row) => row.hierarchy;

  const groupingColDef = {
    headerName: "Offer",
    renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
  };

  const getRowClassName = (params) => {
    const row = params.row;

    if (row.totalName && row?.totalName === "TOTAL") {
      return "total-row";
    }
    if (row?.hierarchy?.length === 1) {
      return "highlighted-row";
    }

    return "";
  };

  const calculateFieldSum = (fieldName) => {
    if (state.newArray.length > 0 && state.filterOn) {
      return state.newArray
        .filter((offer) => offer.hierarchy.length === 2)
        .reduce((sum, offer) => sum + (offer[fieldName] || 0), 0);
    } else {
      return state.offers
        .filter((offer) => offer.hierarchy.length === 1)
        .reduce((sum, offer) => sum + (offer[fieldName] || 0), 0);
    }
  };

  const roundDecimal = (numero) => {
    const numeroRedondeado = Math.round(numero * 10) / 10;
    return numeroRedondeado;
  };

  const totalPendingSum = calculateFieldSum("totalPending");
  const totalClicksSum = calculateFieldSum("totalClicks");
  const totalDeclinedSum = calculateFieldSum("totalDecline");
  const totalRevenueSum = `${roundDecimal(calculateFieldSum("totalRevenue"))}`;
  const totalPayoutSum = `${roundDecimal(calculateFieldSum("totalPayout"))}`;
  const totalProfitSum = `${roundDecimal(calculateFieldSum("totalProfit"))}`;
  const totalPayoutAffiliateSum = `${roundDecimal(
    calculateFieldSum("totalPayoutAffiliate")
  )}`;

  const totalCrSum = roundDecimal((totalPendingSum / totalClicksSum) * 100);

  const totalRow = {
    id: state.offers.length + 1,
    hierarchy: [state.offers.length + 1],
    totalPending: totalPendingSum,
    totalClicks: totalClicksSum,
    totalDecline: totalDeclinedSum,
    totalRevenue: totalRevenueSum,
    totalPayout: totalPayoutSum,
    totalPayoutAffiliate: totalPayoutAffiliateSum,
    totalProfit: totalProfitSum,
    totalCr: isNaN(totalCrSum) ? 0 : totalCrSum,
    totalName: "TOTAL",
  };

  const updatedOffers = [...state.offers, totalRow];

  return (
    <div style={{ height: "100%", width: "100%", backgroundColor: "#f8f7ff" }}>
      <Box sx={{ padding: "15px 30px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "12px",
          }}
        >
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: "15px" }}>
              <TextField
                type="text"
                label="Affiliate ID / Affiliate Title"
                value={state.searchText}
                disabled={state.isLoadingRows}
                onChange={handleSearchTextChange}
                onClick={() => updateState({ isTextFieldClicked: true })}
                sx={{
                  width: "350px",
                  backgroundColor: "white",
                  position: "relative",
                }}
              />

              {state.isSearching &&
                state.affiliates.length === 0 &&
                state.searchText !== "" && (
                  <List
                    component="ul"
                    className="autocomplete-ul"
                    sx={{
                      position: "absolute",
                      width: "350px",
                      zIndex: "1000",
                    }}
                  >
                    <ListItem className="autocomplete-li" disabled={true}>
                      <ListItemText primary="Offer no encontrada" />
                    </ListItem>
                  </List>
                )}

              {state.isSearching &&
                state.isListOpen &&
                state.affiliates.length > 0 && (
                  <List
                    component="ul"
                    className="autocomplete-ul"
                    sx={{
                      width: "350px",
                      zIndex: "1000",
                      position: "absolute",
                    }}
                  >
                    {state.isSearching &&
                      state.isListOpen &&
                      state.affiliates.map((result) => (
                        <ListItem
                          key={result.affiseID}
                          onClick={() => handleLiClick(result)}
                          className="autocomplete-li"
                          disabled={state.isLoadingRows}
                          sx={{
                            color: "black",
                            fontWeight: "700",
                            margin: "5px 0px",
                            "&:hover": {
                              backgroundColor: "var(--blueLowTwo)",
                            },
                          }}
                        >
                          <ListItemText primary={result.title} />
                        </ListItem>
                      ))}
                  </List>
                )}
            </div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateRangePicker
                localeText={{
                  start: `${state.dateFrom}`,
                  end: `${state.dateTo}`,
                }}
                sx={{ backgroundColor: "white", marginRight: "10px" }}
                onChange={(newValue) => {
                  if (newValue[0]) updateState({ dateFrom: newValue[0] });
                  if (newValue[1]) updateState({ dateTo: newValue[1] });
                }}
                disableFuture
              />
            </LocalizationProvider>
            <ThemeProvider theme={theme}>
              <Button
                variant="outlined"
                onClick={handleSubmit}
                disabled={!state.enableButton}
              >
                Generar
              </Button>
            </ThemeProvider>
          </div>
        </div>
        <DataGridPro
          slots={{
            toolbar: () => <CustomToolBar apiRef={apiRef} />,
            loadingOverlay: () => <LinearProgress />,
          }}
          loading={state.isLoadingRows ? true : false}
          treeData
          rows={updatedOffers}
          columns={columns}
          getTreeDataPath={getTreeDataPath}
          groupingColDef={groupingColDef}
          rowHeight={30}
          getRowClassName={getRowClassName}
          sx={{ backgroundColor: "white" }}
          apiRef={apiRef}
        />
      </Box>
    </div>
  );
}
