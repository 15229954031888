import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material";
import { CloudDownload, CloudUpload } from "@mui/icons-material";
import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";
import { boardStyles } from "../../../../styles/InLineStyles/BoardStyles.js";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { UserContext } from "../../../../UserContext";
import { fetchData, getProgressStatusQueue } from "../../../../utils/ApiCall";
import container from "../../../../styles/Global.module.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import Typography from "@mui/material/Typography";
import Swal from "sweetalert2";
import { Currencies } from "../../../../json/Currencies.js";
import PropTypes from "prop-types";
import { useCustomState } from "../../../../utils/useCustomState.js";

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="black">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

const FileUploader = ({ onFileUploaded }) => {
  const [loading, setLoading] = useState(false);

  const onDrop = async (acceptedFiles) => {
    setLoading(true);
    const file = acceptedFiles[0];
    const data = await parseExcel(file);
    onFileUploaded(data);
    setLoading(false);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      {...getRootProps()}
      style={{
        cursor: "pointer",
        width: "850px",
        height: "100px",
      }}
    >
      <input {...getInputProps()} />
      <Box
        style={{
          padding: "10px",
          textAlign: "center",
          border: "2px dashed var(--blueFour)",
          backgroundColor: "white",
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : isDragActive ? (
          <div>
            <CloudUpload fontSize="large" />
            <p>Arrastrar aqui</p>
          </div>
        ) : (
          <div>
            <CloudUpload fontSize="large" />
            <p>Elegir archivos o arrastrar aquí para poder editar</p>
          </div>
        )}
      </Box>
    </div>
  );
};

const parseExcel = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const binaryString = event.target.result;
      const workbook = XLSX.read(binaryString, { type: "binary" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const convertedData = data.slice(1).map((row, index) => {
        let status;
        switch (row[2]) {
          case 1:
            status = "CONFIRMED";
            break;
          case 2:
            status = "PENDING";
            break;
          case 3:
            status = "DECLINED";
            break;
          default:
            status = "UNKNOWN";
        }

        let type;
        switch (row[1]) {
          case "clickid":
            type = "Click ID";
            break;
          case "custom":
            type = "Custom";
            break;
          default:
            type = "Unknown";
        }
        return {
          id: row[0],
          type: type,
          status: status,
          comment: row[3],
          revenue: row[4],
          payout: row[5],
          rowIndex: index,
        };
      });

      resolve(convertedData);
    };
    reader.onerror = (error) => reject(error);
    reader.readAsBinaryString(file);
  });
};

const ExcelDataGrid = ({ data }) => {
  const columns = [
    { field: "id", headerName: "ID", width: 250, headerAlign: "center" },
    { field: "type", headerName: "Type", width: 120, headerAlign: "center" },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      headerAlign: "center",
    },
    {
      field: "comment",
      headerName: "Comment",
      width: 120,
      headerAlign: "center",
    },
    {
      field: "revenue",
      headerName: "Revenue al cierre",
      width: 180,
      headerAlign: "center",
    },
    {
      field: "payout",
      headerName: "Payout al cierre",
      width: 180,
      headerAlign: "center",
    },
    {
      field: "uploadStatus",
      headerName: "Upload Status",
      width: 200,
      headerAlign: "center",
      renderCell: (params) => (
        <div>
          {params.value === "Confirmed" ? (
            <span style={{ display: "flex", alignItems: "center" }}>
              {params?.value?.length > 0 && (
                <CheckIcon sx={{ marginRight: "6px", color: "var(--done)" }} />
              )}

              {params.value}
            </span>
          ) : (
            <span style={{ display: "flex", alignItems: "center" }}>
              {params?.value?.length > 0 && (
                <CloseIcon
                  sx={{ marginRight: "6px", color: "var(--issues)" }}
                />
              )}
              {params.value}
            </span>
          )}
        </div>
      ),
    },
  ];

  return (
    <div style={{ height: 600, width: "100%" }}>
      <DataGridPro
        rows={data}
        columns={columns}
        pageSize={10}
        sx={boardStyles}
        style={{ backgroundColor: "white" }}
      />
    </div>
  );
};

const getTodaysDay = () => {
  const date = new Date().toISOString();
  const first = "01";
  const firstOfMonth = date.slice(0, 8).concat(first);
  const today = date.slice(0, 10);

  return { firstOfMonth, today };
};

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const ValidarConversiones = () => {
  const apiService = `${backendUrl}/api/offer-service`;
  const { key, setKey } = useContext(UserContext);
  setKey(localStorage?.getItem("jwt"));
  const dates = getTodaysDay();

  const [state, updateState] = useCustomState({
    excelData: null,
    conversionRate: "",
    currency: "",
    currenciesList: false,
    clickIds: [],
    customFields: [],
    searchText: "",
    advertisers: [],
    advertiserID: "",
    searchByAdvertiserID: null,
    isSearching: false,
    isListOpen: false,
    isLiClicked: false,
    isSubmitting: false,
    editPayoutRevenue: false,
    editCurrency: false,
    jobId: null,
    progress: null,
    requestCompleted: false,
    responseQueue: null,
    dateFrom: dates.firstOfMonth,
    dateTo: dates.today,
  });

  useEffect(() => {
    const getProgress = async () => {
      try {
        const progressQueue = await getProgressStatusQueue(
          `getStatusEdit?id=${state.jobId}`
        );
        if (progressQueue.state === "completed") {
          updateState({
            progress: 100,
            isSubmitting: false,
            requestCompleted: true,
            responseQueue: progressQueue.result,
          });
        } else {
          updateState({ progress: progressQueue.progress });
        }
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    if (!state.requestCompleted && state.jobId) {
      const interval = setInterval(getProgress, 500);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.jobId]);

  useEffect(() => {
    if (state.requestCompleted && state.responseQueue) {
      try {
        const { failedIds, notFoundIds } = state.responseQueue;

        const updatedExcelData = state.excelData.map((item) => {
          let uploadStatus = "Confirmed";
          if (notFoundIds.includes(item.id)) {
            uploadStatus = "Not Found";
          } else if (failedIds.includes(item.id)) {
            uploadStatus = "Failed";
          }
          return { ...item, uploadStatus: uploadStatus };
        });

        updateState({
          excelData: updatedExcelData,
          isSubmitting: false,
          progress: null,
          requestCompleted: false,
          responseQueue: null,
          jobId: null,
        });
      } catch (e) {
        handleClearAll();
        Swal.fire({
          title: "Todos los ids se editaron con éxito",
          icon: "success",
          confirmButtonText: "OK",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.requestCompleted, state.responseQueue]);

  useEffect(() => {
    const searchOffers = async (query, searchParam) => {
      const url = `${apiService}/autocompleteAdvertisers?${searchParam}=${query}`;
      const resOffers = await fetchData(url);
      updateState({ advertisers: resOffers });
    };

    if (!state.isLiClicked && state.searchText) {
      if (state.searchByAdvertiserID) {
        searchOffers(state.searchText, "affiseID");
      } else {
        searchOffers(state.searchText, "name");
      }
      updateState({
        isSearching: true,
        isListOpen: true,
      });
    } else {
      updateState({
        advertisers: [],
        isSearching: false,
        isListOpen: false,
        isLiClicked: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.searchText, state.searchByAdvertiserID]);

  const handleFileUploaded = (data) => {
    const clickIds = data
      .filter((item) => item.type === "Click ID")
      .map((item) => item.id);
    const customFields = data
      .filter((item) => item.type === "Custom")
      .map((item) => item.id);

    updateState({
      clickIds,
      customFields,
      excelData: data,
    });
  };

  const handleRateChange = (event) => {
    const newRate = parseFloat(event.target.value);
    updateState({ conversionRate: isNaN(newRate) ? 0 : newRate });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    updateState({ isSubmitting: true });

    const formattedDateFrom = state.dateFrom
      ? dayjs(state.dateFrom).format("YYYY-MM-DD")
      : "";
    const formattedDateTo = state.dateTo
      ? dayjs(state.dateTo).format("YYYY-MM-DD")
      : "";

    const clickIdsWithStatus = state.clickIds.map((id) => {
      const item = state.excelData.find((item) => item.id === id);
      return {
        id,
        status: item?.status.toLowerCase(),
        comment: item?.comment,
        revenue: item?.revenue ?? null,
        payout: item?.payout ?? null,
      };
    });

    const customFieldsWithStatus = state.customFields.map((id) => {
      const item = state.excelData.find((item) => item.id === id);
      return {
        id,
        status: item?.status.toLowerCase(),
        comment: item?.comment,
        revenue: item?.revenue ?? null,
        payout: item?.payout ?? null,
      };
    });

    const data = {
      ids: {
        clickids: clickIdsWithStatus,
        customfields: customFieldsWithStatus,
      },
      dates: {
        date_from: formattedDateFrom,
        date_to: formattedDateTo,
      },
      rate: state.conversionRate,
      advertiser: state.advertiserID,
      currency: state.currency,
    };

    try {
      const response = await fetch(
        `${backendUrl}/api/offer-service/editConversions`,
        {
          method: "POST",
          headers: {
            Accept: "/",
            "Content-Type": "application/json",
            Authorization: `Bearer ${key}`,
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        const responseJSON = await response.json();
        updateState({
          jobId: responseJSON.jobId,
          progress: 0,
        });
      } else {
        console.error("Error al enviar los datos:", response.statusText);
        updateState({ isSubmitting: false });
      }
    } catch (error) {
      console.error("Error al enviar los datos:", error.message);
      updateState({ isSubmitting: false });
    }
  };

  const handleLiClick = (value) => {
    if (value) {
      updateState({
        searchText: value.name,
        advertiserID: value.affiseID,
        isListOpen: false,
        isSearching: false,
        isLiClicked: true,
      });
    }
  };

  const handleSearchTextChange = (e) => {
    updateState({
      searchText: e.target.value,
      searchByAdvertiserID: !isNaN(e.target.value),
    });
  };

  const handleClearAll = () => {
    updateState({
      excelData: null,
      dateFrom: dates.firstOfMonth,
      dateTo: dates.today,
      conversionRate: "",
      clickIds: [],
      customFields: [],
      searchText: "",
      advertiserID: "",
      searchByAdvertiserID: null,
      isSearching: false,
      isListOpen: false,
      isLiClicked: false,
      isSubmitting: false,
      currency: "",
      editPayoutRevenue: false,
      editCurrency: false,
    });
  };

  const handleCurrenciesList = () => {
    updateState({ currenciesList: !state.currenciesList });
  };

  const handleCurrencySelect = (currency) => {
    updateState({
      currency,
      currenciesList: false,
    });
  };

  const handleEditPayoutRevenue = () => {
    updateState({
      editPayoutRevenue: !state.editPayoutRevenue,
      conversionRate: state.editPayoutRevenue ? "" : state.conversionRate,
    });
  };

  const handleEditCurrency = () => {
    updateState({
      editCurrency: !state.editCurrency,
      currency: state.editCurrency ? "" : state.currency,
    });
  };

  const handleDownloadExcel = () => {
    const filePath = "/EjemploValidaciones.xlsx";
    const link = document.createElement("a");
    link.href = filePath;
    link.download = "EjemploValidaciones.xlsx";
    link.click();
  };

  return (
    <Box
      className={container.globalContainer}
      style={{ padding: "15px 30px" }}
      component="form"
    >
      {state.isSubmitting && (
        <Box
          sx={{
            position: "absolute",
            top: "0%",
            left: "0%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100vw",
            height: "100vh",
            background: "rgba(255, 255, 255, 0.85)",
            zIndex: 2,
          }}
        >
          <CircularProgressWithLabel value={state.progress} />
          <Typography
            variant="h6"
            component="div"
            sx={{ marginTop: "10px" }}
            color="black"
          >
            Submitting...
          </Typography>
        </Box>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            width: "850px",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateRangePicker
                localeText={{
                  start: `${state.dateFrom}`,
                  end: `${state.dateTo}`,
                }}
                sx={{
                  backgroundColor: "white",
                  marginRight: "10px",
                }}
                onChange={(newValue) => {
                  if (newValue[0]) updateState({ dateFrom: newValue[0] });
                  if (newValue[1]) updateState({ dateTo: newValue[1] });
                }}
              />
            </LocalizationProvider>

            <div style={{ width: "350px" }}>
              <TextField
                type="text"
                label="Advertiser ID / Advertiser Title"
                value={state.searchText}
                onChange={handleSearchTextChange}
                sx={{
                  width: "100%",
                  backgroundColor: "white",
                  position: "relative",
                }}
              />

              {state.isSearching &&
                state.advertisers.length === 0 &&
                state.searchText !== "" && (
                  <List
                    component="ul"
                    className="autocomplete-ul"
                    sx={{
                      position: "absolute",
                      width: "350px",
                      zIndex: "1",
                    }}
                  >
                    <ListItem className="autocomplete-li" disabled={true}>
                      <ListItemText primary="Advertiser no encontrado" />
                    </ListItem>
                  </List>
                )}

              {state.isListOpen && state.advertisers.length > 0 && (
                <List
                  component="ul"
                  className="autocomplete-ul"
                  sx={{
                    width: "350px",
                    zIndex: "1",
                    position: "absolute",
                  }}
                >
                  {state.advertisers.map((result) => (
                    <ListItem
                      key={result.affiseID}
                      onClick={() => handleLiClick(result)}
                      className="autocomplete-li"
                      sx={{
                        color: "black",
                        fontWeight: "700",
                        margin: "5px 0px",
                        "&:hover": {
                          backgroundColor: "var(--blueLowTwo)",
                        },
                      }}
                    >
                      <ListItemText primary={result.name} />
                    </ListItem>
                  ))}
                </List>
              )}
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ display: "flex", marginRight: "15px" }}>
              <Box sx={{ width: "30px" }}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onClick={handleEditPayoutRevenue}
                        checked={state.editPayoutRevenue}
                        sx={{ marginTop: "5px" }}
                      />
                    }
                  />
                </FormGroup>
              </Box>
              <TextField
                label="Tasa de Conversión"
                variant="outlined"
                value={state.conversionRate}
                onChange={handleRateChange}
                type="number"
                disabled={!state.editPayoutRevenue}
                sx={{
                  backgroundColor: "white",
                  position: "relative",
                  marginRight: "10px",
                }}
              />
            </div>

            <div style={{ display: "flex", marginRight: "15px" }}>
              <Box sx={{ width: "30px" }}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onClick={handleEditCurrency}
                        checked={state.editCurrency}
                        sx={{ marginTop: "5px" }}
                      />
                    }
                  />
                </FormGroup>
              </Box>
              <div>
                <TextField
                  label="Currency"
                  variant="outlined"
                  value={state.currency}
                  onClick={handleCurrenciesList}
                  disabled={!state.editCurrency}
                  sx={{
                    backgroundColor: "white",
                    position: "relative",
                    marginRight: "10px",
                    width: "100px",
                  }}
                />
                {state.currenciesList && state.editCurrency && (
                  <List
                    component="ul"
                    className="autocomplete-ul"
                    sx={{
                      width: "100px",
                      zIndex: "9999",
                      position: "absolute",
                    }}
                  >
                    {Currencies.map((c, index) => (
                      <ListItem
                        key={index}
                        onClick={() => handleCurrencySelect(c)}
                        className="autocomplete-li"
                        sx={{
                          color: "black",
                          fontWeight: "700",
                          margin: "3px 0px",
                          padding: "5px",
                          "&:hover": {
                            backgroundColor: "var(--blueLowTwo)",
                          },
                        }}
                      >
                        <ListItemText primary={c} />
                      </ListItem>
                    ))}
                  </List>
                )}
              </div>
            </div>

            <Button
              variant="contained"
              onClick={handleSubmit}
              type="submit"
              sx={{ height: "auto", width: "200px" }}
              disabled={state.excelData === null}
            >
              Editar
            </Button>

            <Button
              variant="contained"
              onClick={handleClearAll}
              disabled={state.isSubmitting}
              sx={{ marginLeft: "10px", backgroundColor: "var(--issues)" }}
            >
              Clear All
            </Button>
          </div>
          <FileUploader onFileUploaded={handleFileUploaded} />
        </Box>
        <div style={{ margin: "0px 10px" }}>
          <TableContainer
            sx={{
              width: "200px",
              backgroundColor: "white",
              marginBottom: "10px",
            }}
          >
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell
                    sx={{ fontWeight: "600" }}
                    align="center"
                    colSpan={2}
                  >
                    STATUS
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Confirmed</TableCell>
                  <TableCell>1</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Pending</TableCell>
                  <TableCell>2</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Declined</TableCell>
                  <TableCell>3</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Button
            variant="contained"
            onClick={handleDownloadExcel}
            style={{ marginLeft: "10px" }}
            sx={{
              height: "auto",
              backgroundColor: "var(--blueFour)",
              "&:hover": {
                backgroundColor: "var(--blue)",
              },
            }}
            startIcon={<CloudDownload />}
          >
            Descargar ejemplo
          </Button>
        </div>
      </div>

      <Grid item xs={12} sx={{ marginTop: "2em" }}>
        <ExcelDataGrid data={state.excelData || []} />
      </Grid>
    </Box>
  );
};

export default ValidarConversiones;
