import {
  Box,
  createTheme,
  Menu,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useMemo } from "react";
import { editDate } from "../../../utils/ApiCall";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useCustomState } from "../../../utils/useCustomState";
require("dayjs/locale/es");

const SingleDuration = ({ row, value, selectionRow, isReminder, fieldKey }) => {
  const pathname = useMemo(() => window.location.pathname.replace("/", ""), []);
  const isMobile = useMemo(() => window.innerWidth <= 600, []);

  const defaultDate = row[fieldKey] || null;

  const [state, updateState] = useCustomState({
    dateFrom: defaultDate,
    open: false,
    anchorEl: null,
  });

  const renderStartDate = useMemo(() => {
    if (pathname === "it-request" && row?.createdAt) {
      return dayjs(row.createdAt).locale("es").format("DD MMMM YYYY");
    }
    return state.dateFrom
      ? dayjs(state.dateFrom).locale("es").format("MMM. D")
      : "Agregar fecha";
  }, [state.dateFrom, pathname, row]);

  const theme = useMemo(
    () =>
      createTheme({
        components: {
          MuiLinearProgress: {
            styleOverrides: {
              root: {
                borderRadius: "50px",
                backgroundColor: "var(--blackLow)",
              },
              bar: {
                backgroundColor: "var(--done)",
              },
            },
          },
        },
      }),
    []
  );

  const cellRef = useRef(null);

  useEffect(() => {
    setTimeout(window.unlock, 100);
  }, []);

  const handleClick = (event) => {
    updateState({ open: true, anchorEl: event.currentTarget });
  };

  const handleClose = () => {
    updateState({ open: false, anchorEl: null });
  };

  const handleDateChange = (newDate) => {
    updateState({ dateFrom: newDate?.$d });

    if (newDate) {
      const date = newDate.$d;
      const targetRow = selectionRow.length > 0 ? selectionRow : [row.id];
      editDate(pathname, date, "", targetRow, isReminder);
    }
  };

  const menuStyle = {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    width: "auto",
  };

  const menuProps = {
    anchorEl: state.anchorEl,
    open: state.open,
    onClose: handleClose,
    PaperProps: {
      elevation: 0,
      sx: {
        ...menuStyle,
        marginLeft: isMobile ? "0px" : "122px",
      },
    },
    transformOrigin: { horizontal: "right", vertical: "top" },
    anchorOrigin: { horizontal: "right", vertical: "bottom" },
  };

  return (
    <div ref={cellRef} style={{ position: "relative", cursor: "pointer" }}>
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
          onClick={pathname === "it-request" ? null : handleClick}
        >
          <Typography
            variant="caption"
            component="div"
            color="inherit"
            sx={{ width: "120px", textAlign: "center" }}
          >
            {renderStartDate}
          </Typography>
        </Box>
      </ThemeProvider>
      <Menu {...menuProps}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            onChange={handleDateChange}
            defaultValue={dayjs(defaultDate || Date.now())}
          />
        </LocalizationProvider>
      </Menu>
    </div>
  );
};

export default SingleDuration;
