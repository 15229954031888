import { Box, Button, Menu, TextField, Tooltip } from "@mui/material";
import React, { useRef } from "react";
import { editBoardField } from "../../../utils/ApiCall";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Swal from "sweetalert2";
import { useCustomState } from "../../../utils/useCustomState";

const EditableText = ({
  row,
  field,
  handleEditResponseChange,
  initialValue,
  styleText,
}) => {
  const currentUrl = window.location.pathname;

  const [state, updateState] = useCustomState({
    newComment: initialValue || "",
    anchorEl: null,
    open: false,
    windowWidth: window.innerWidth,
    saveButton: "Guardar",
    disableSave: false,
  });

  const isMobile = state.windowWidth <= 600;
  const cellRef = useRef(null);

  const handleClose = () => {
    updateState({
      anchorEl: null,
      open: false,
    });
  };

  const handleClick = (event) => {
    updateState({
      anchorEl: event.currentTarget,
      open: true,
    });
  };

  const cellStyle = {
    position: "relative",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "300px",
    cursor: "pointer",
  };

  const menuStyle = {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    width: 350,
    height: isMobile ? 600 : "auto",
    overflowY: isMobile ? "scroll" : "",
    paddingRight: isMobile ? "20px" : "0px",
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: "24.3%",
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  };

  const menuProps = {
    anchorEl: state.anchorEl,
    id: "account-menu",
    open: state.open,
    onClose: handleClose,
    PaperProps: {
      elevation: 0,
      sx: {
        ...menuStyle,
        marginLeft: isMobile ? "0px" : "50px",
      },
    },
    transformOrigin: { horizontal: "right", vertical: "top" },
    anchorOrigin: { horizontal: "right", vertical: "bottom" },
  };

  const handleSubmit = async () => {
    const detail = await editBoardField(
      currentUrl,
      [row.id],
      field,
      state.newComment
    );
    if (detail[0]?.status === 400) {
      Swal.fire(
        `${detail[0]?.data?.error?.message ?? "Bad Request"}`,
        "",
        "error"
      );
      handleClose();
    } else {
      handleEditResponseChange(state.newComment, row.id, field);
      handleClose();
    }
  };

  return (
    <Box>
      <Tooltip title={initialValue ?? null}>
        <div onClick={handleClick} ref={cellRef} style={cellStyle}>
          {initialValue === null ||
          initialValue === "" ||
          initialValue === undefined ? (
            <AddCircleIcon sx={{ color: "var(--blackLow)" }} />
          ) : styleText ? (
            <span>{initialValue}</span>
          ) : (
            initialValue
          )}
        </div>
      </Tooltip>
      <Menu {...menuProps}>
        <TextField
          sx={{ width: "100%" }}
          multiline
          minRows={3}
          value={state.newComment}
          onChange={(e) =>
            updateState({
              newComment: e.target.value,
            })
          }
        />
        <div
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            sx={{ marginTop: "20px" }}
            onClick={handleSubmit}
            disabled={state.disableSave}
          >
            {state.saveButton}
          </Button>
        </div>
      </Menu>
    </Box>
  );
};

export default EditableText;
