import React, { useRef } from "react";
import { Autocomplete, Box, Button, Menu, TextField } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { editBoardField } from "../../../utils/ApiCall";
import { useCustomState } from "../../../utils/useCustomState";

const EditableSelectMultiple = ({
  row,
  field,
  handleEditResponseChange,
  options,
  getOptionLabelFunc,
  initialValue,
  label,
}) => {
  const currentUrl = window.location.pathname;
  const initialSelectedValues = initialValue
    ? initialValue.split(",").map((item) => item.trim())
    : [];

  const [state, updateState] = useCustomState({
    selectedValue: initialSelectedValues,
    open: false,
    anchorEl: null,
    saveButtonLabel: "Guardar",
    disableSave: false,
    isMobile: window.innerWidth <= 600,
  });

  const cellRef = useRef(null);

  const handleClick = (event) => {
    updateState({
      open: true,
      anchorEl: event.currentTarget,
    });
  };

  const handleClose = () => {
    updateState({
      open: false,
      anchorEl: null,
    });
  };

  const handleAutocompleteChange = (_, newValue) => {
    updateState({
      selectedValue: newValue,
    });
  };

  const handleSubmit = async () => {
    updateState({
      saveButtonLabel: "Guardando",
      disableSave: true,
    });

    try {
      const valueData = state.selectedValue.map((item) => item).join(", ");
      await editBoardField(currentUrl, [row.id], field, valueData);
      handleEditResponseChange(valueData, row.id, field);
    } finally {
      updateState({
        saveButtonLabel: "Guardar",
        disableSave: false,
      });
      handleClose();
    }
  };

  const cellStyle = {
    position: "relative",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "160px",
    cursor: "pointer",
  };

  const menuStyle = {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    width: 350,
    height: state.isMobile ? 600 : "auto",
    overflowY: state.isMobile ? "scroll" : "",
    paddingRight: state.isMobile ? "20px" : "0px",
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: "24%",
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  };

  return (
    <Box>
      <div onClick={handleClick} ref={cellRef} style={cellStyle}>
        {initialValue ? (
          <span className="cell-data">{initialValue}</span>
        ) : (
          <AddCircleIcon sx={{ color: "var(--blackLow)" }} />
        )}
      </div>
      <Menu
        anchorEl={state.anchorEl}
        open={state.open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            ...menuStyle,
            marginLeft: state.isMobile ? "0px" : "10px",
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Autocomplete
          size="small"
          disablePortal
          options={options}
          getOptionLabel={getOptionLabelFunc}
          value={state.selectedValue}
          onChange={handleAutocompleteChange}
          multiple
          renderInput={(params) => (
            <TextField {...params} label={label} variant="standard" />
          )}
          sx={{ width: "100%" }}
        />
        <div
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            sx={{ marginTop: "20px" }}
            onClick={handleSubmit}
            disabled={state.disableSave}
          >
            {state.saveButtonLabel}
          </Button>
        </div>
      </Menu>
    </Box>
  );
};

export default EditableSelectMultiple;
