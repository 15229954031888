import { Box, ThemeProvider, createTheme } from "@mui/material";
import React, { useEffect, useContext } from "react";
import { UserContext } from "../../../../UserContext";
import SmallCard from "./SmallCard/SmallCard";
import container from "../../../../styles/Global.module.css";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import LineChartComparison from "../../../../components/global/LineChartComparison";
import GridList from "./GridList/GridList";
import GridTop from "./GridTop/GridTop";
import { CircularProgress } from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";
import { useCustomState } from "../../../../utils/useCustomState";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const HomeDashBoard = () => {
  const [state, updateState] = useCustomState({
    chartDataY: [],
    chartDataX: [],
    chartData: [],
    totalRevenue: 0,
    totalProfit: 0,
    totalCost: 0,
    newAffiliates: [],
    topAffiliates: [],
    isLoading: true,
  });

  const { key, setKey } = useContext(UserContext);

  setKey(localStorage?.getItem("jwt"));

  useEffect(() => {
    const fetchApi = async () => {
      if (key) {
        try {
          const resClicks = await fetch(
            `${backendUrl}/api/aluna-dashboard/dailyClicks`,
            {
              headers: {
                Authorization: `Bearer ${key}`,
              },
            }
          );
          const resConversions = await fetch(
            `${backendUrl}/api/aluna-dashboard/dailyConversions`,
            {
              headers: {
                Authorization: `Bearer ${key}`,
              },
            }
          );

          const resTotalRevenue = await fetch(
            `${backendUrl}/api/aluna-dashboard/totalRevenue`,
            {
              headers: {
                Authorization: `Bearer ${key}`,
              },
            }
          );

          const resNewAffiliates = await fetch(
            `${backendUrl}/api/aluna-dashboard/newAffiliates`,
            {
              headers: {
                Authorization: `Bearer ${key}`,
              },
            }
          );
          const allNewAffiliates = await resNewAffiliates.json();
          updateState({ newAffiliates: allNewAffiliates });

          const resTopAffiliates = await fetch(
            `${backendUrl}/api/aluna-dashboard/topAffiliates`,
            {
              headers: {
                Authorization: `Bearer ${key}`,
              },
            }
          );
          const allTopAffiliates = await resTopAffiliates.json();
          updateState({ topAffiliates: allTopAffiliates });

          const totalRev = await resTotalRevenue.json();
          updateState({ totalRevenue: totalRev });

          const resTotalProfit = await fetch(
            `${backendUrl}/api/aluna-dashboard/totalProfit`,
            {
              headers: {
                Authorization: `Bearer ${key}`,
              },
            }
          );
          const totalProf = await resTotalProfit.json();
          updateState({ totalProfit: totalProf });

          const resTotalCost = await fetch(
            `${backendUrl}/api/aluna-dashboard/totalCost`,
            {
              headers: {
                Authorization: `Bearer ${key}`,
              },
            }
          );
          const totalCos = await resTotalCost.json();
          updateState({ totalCost: totalCos });

          let clickResponse = await resClicks.json();
          updateState({ chartData: clickResponse });

          clickResponse = clickResponse.dailyClicks;
          let clickConversions = await resConversions.json();
          clickConversions = clickConversions.dailyConversions;

          updateState({ chartDataX: Object.keys(clickResponse) });

          const series = [];
          const totalClick = Object.values(clickResponse).reduce(
            (total, current) => total + current,
            0
          );
          const totalConversiones = Object.values(clickConversions).reduce(
            (total, current) => total + current,
            0
          );
          series.push({
            data: Object.values(clickResponse),
            label: `${totalClick?.toLocaleString()} Clicks`,
          });
          series.push({
            data: Object.values(clickConversions),
            label: `${totalConversiones?.toLocaleString()} Conversions`,
          });
          updateState({
            chartDataY: series,
            isLoading: false,
          });
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };
    fetchApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  const theme = createTheme({
    components: {
      MuiCircularProgress: {
        styleOverrides: {
          colorPrimary: {
            color: "var(--blackLow)",
            opacity: 0.2,
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Box className={container.globalContainer}>
        {/* HEADER */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box></Box>
        </Box>

        {/* GRID & CHARTS */}
        <Box
          display="grid"
          gridTemplateColumns="repeat(6, 1fr)"
          columnGap="20px"
          rowGap="20px"
        >
          {/* ROW 1 */}
          <Box gridColumn="span 2" gridRow="span 1" height="200px" width="100%">
            <SmallCard
              icon={
                <AttachMoneyIcon
                  sx={{ fontSize: "40px", color: "text.secondary" }}
                />
              }
              title="Total Revenue"
              data={state.totalRevenue}
              isLoading={state.isLoading}
              progress={<CircularProgress />}
              timeIcon={
                <HistoryIcon sx={{ fontSize: "16px", marginRight: "5px" }} />
              }
            />
          </Box>
          <Box gridColumn="span 2" gridRow="span 1" height="200px">
            <SmallCard
              icon={
                <AttachMoneyIcon
                  sx={{ fontSize: "40px", color: "text.secondary" }}
                />
              }
              title="Total Profit"
              data={state.totalProfit}
              isLoading={state.isLoading}
              progress={<CircularProgress />}
              timeIcon={
                <HistoryIcon sx={{ fontSize: "16px", marginRight: "5px" }} />
              }
            />
          </Box>
          <Box gridColumn="span 2" gridRow="span 1" height="200px">
            <SmallCard
              icon={
                <AttachMoneyIcon
                  sx={{ fontSize: "40px", color: "text.secondary" }}
                />
              }
              title="Total Cost"
              data={state.totalCost}
              isLoading={state.isLoading}
              progress={<CircularProgress />}
              timeIcon={
                <HistoryIcon sx={{ fontSize: "16px", marginRight: "5px" }} />
              }
              moreExpensive={
                state.totalCost?.total?.total >
                state.totalCost?.total?.lastMonth
                  ? "var(--issues)"
                  : undefined
              }
            />
          </Box>

          {/* ROW 2 */}
          <Box gridColumn="span 3" gridRow="span 1" maxHeight="400px">
            <LineChartComparison
              series={state.chartDataY}
              xAxisData={state.chartDataX}
              isLoading={state.isLoading}
              progress={<CircularProgress />}
              timeIcon={
                <HistoryIcon sx={{ fontSize: "16px", marginRight: "5px" }} />
              }
              chartData={state.chartData}
            />
          </Box>

          <Box gridColumn="4 / 5" gridRow="span 1" maxHeight="400px">
            <GridList
              newAffiliates={state.newAffiliates}
              isLoading={state.isLoading}
              progress={<CircularProgress />}
              timeIcon={
                <HistoryIcon sx={{ fontSize: "16px", marginRight: "5px" }} />
              }
            />
          </Box>

          <Box gridColumn="5 / 7" gridRow="span 1" maxHeight="400px">
            <GridTop
              topAffiliates={state.topAffiliates}
              isLoading={state.isLoading}
              progress={<CircularProgress />}
              timeIcon={
                <HistoryIcon sx={{ fontSize: "16px", marginRight: "5px" }} />
              }
            />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default HomeDashBoard;
