import React, { useEffect } from "react";
import container from "../../../../styles/Global.module.css";
import Threads from "../../../Threads/Threads.jsx";
import {
  tasksAlunaStatus,
  tasksAlunaType,
} from "../../../../json/StatusOptions.js";
import ColumnStatus from "../../../../components/global/fields/ColumnStatus.jsx";
import UsersSelect from "../../../../components/global/fields/UsersSelect.jsx";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import EditableText from "../../../../components/global/fields/EditableText.jsx";
import { getStatus } from "../../../../utils/ApiCall.js";
import BaseBoard from "../../../global/BoardTemplate/BaseBoard.jsx";
import { GridLogicOperator } from "@mui/x-data-grid-pro";
import { useCustomState } from "../../../../utils/useCustomState.js";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const TasksAluna = () => {
  const apiUrl = `${backendUrl}/api/tasks-aluna/items`;
  const apiUsers = `${backendUrl}/api/users`;

  const [state, updateState] = useCustomState({
    status: "Open",
    groups: ["Open", "Done"],
    users: [],
    items: [],
    itemsDone: [],
    selectionRow: [],
    updateRows: [],
    columnsFilter: { id: false },
    viewFilter: {
      items: [],
      quickFilterLogicOperator: GridLogicOperator.Or,
    },
    pinnedColumns: {
      left: ["__check__", "threads"],
      right: [],
    },
  });

  useEffect(() => {
    const fetchApi = async () => {
      const resUsers = await fetch(apiUsers);
      const data = await resUsers.json();
      updateState({ users: data });
    };
    fetchApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterItemsByStatus = (items, status, condition) => {
    if (condition) {
      return items?.filter((item) => item.status === status);
    }
    return items?.filter((item) => item.status !== status);
  };

  const filterItemsAndSetGroups = (items) => {
    updateState({
      itemsDone: filterItemsByStatus(items, "Done", true),
      items: filterItemsByStatus(items, "Done", false),
    });
  };

  const handleEditResponseChange = async (newValue, rowID, field) => {
    let status;
    if (field !== "status") {
      status = await getStatus(rowID, "tasks-aluna");
    } else {
      status = newValue;
    }

    if (status === "Done") {
      const updatedRowsTerminados = state.itemsDone.map((row) => {
        if (+row.id === +rowID) {
          return { ...row, [`${field}`]: newValue };
        }
        return row;
      });
      updateState({ itemsDone: updatedRowsTerminados });
    } else {
      const updatedRowsNoTerminados = state.items.map((row) => {
        if (+row.id === +rowID) {
          return { ...row, [`${field}`]: newValue };
        }
        return row;
      });
      updateState({ items: updatedRowsNoTerminados });
    }
  };
  const updateBoard = (newData) => {
    const updatedRows = [...state.updateRows, newData];

    updateState({ updateRows: updatedRows });
  };

  const getGroupRows = (groupName) => {
    return groupName === "Open" ? state.items : state.itemsDone;
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 70,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "name",
      headerName: "Elemento",
      width: 300,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"name"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.name}
        />
      ),
    },
    {
      field: "threads",
      headerName: "Thread",
      width: 70,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => {
        return <Threads {...params} row={params.row} />;
      },
    },
    {
      field: "owner.username",
      headerName: "Owner",
      width: 140,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.row.owner?.username,
    },
    {
      field: "status",
      headerName: "Status",
      width: 160,
      cellClassName: "no-padding",
      headerAlign: "center",

      renderCell: (params) => (
        <ColumnStatus
          {...params}
          selectionRow={state.selectionRow}
          field={"status"}
          columnPosition={280}
          handleEditResponseChange={handleEditResponseChange}
          statusOption={tasksAlunaStatus}
        />
      ),
    },
    {
      field: "type",
      headerName: "Type",
      width: 160,
      cellClassName: "no-padding",
      headerAlign: "center",

      renderCell: (params) => (
        <ColumnStatus
          {...params}
          selectionRow={state.selectionRow}
          field={"type"}
          columnPosition={280}
          handleEditResponseChange={handleEditResponseChange}
          statusOption={tasksAlunaType}
        />
      ),
    },
    {
      field: "responsibles",
      headerName: "Responsable",
      width: 140,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <UsersSelect
          {...params}
          users={state.users}
          field={"responsibles"}
          handleEditResponseChange={handleEditResponseChange}
        />
      ),
    },
    {
      field: "updatedAt",
      headerName: "Ultima actualizacion",
      width: 160,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const updatedAt = new Date(params.row.updatedAt);
        const timeAgo = formatDistanceToNow(updatedAt, { addSuffix: true });
        return <p>{timeAgo}</p>;
      },
    },
  ];

  return (
    <div className={container.globalContainer}>
      <BaseBoard
        columns={columns}
        groups={state.groups}
        getGroupRows={getGroupRows}
        setGroupItems={filterItemsAndSetGroups}
        apiUrl={apiUrl}
        status={state.status}
        setStatus={(status) => updateState({ status })}
        updateBoard={updateBoard}
        updateRows={state.updateRows}
        setSelectionRow={(selectionRow) => updateState({ selectionRow })}
        selectionRow={state.selectionRow}
        viewFilter={state.viewFilter}
        setViewFilter={(viewFilter) => updateState({ viewFilter })}
        columnsFilter={state.columnsFilter}
        setColumnsFilter={(columnsFilter) => updateState({ columnsFilter })}
        pinnedColumns={state.pinnedColumns}
        setPinnedColumns={(pinnedColumns) => updateState({ pinnedColumns })}
        checkboxSelection={true}
        height={"80vh"}
        maxHeight={"80vh"}
      />
    </div>
  );
};

export default TasksAluna;
