import React, { useEffect } from "react";
import Header from "../../../../components/global/Header";
import container from "../../../../styles/Global.module.css";
import Threads from "../../../Threads/Threads";
import { pedidosItStatus, pedidosItTags } from "../../../../json/StatusOptions";
import ColumnStatus from "../../../../components/global/fields/ColumnStatus.jsx";
import BaseBoard from "../../../global/BoardTemplate/BaseBoard.jsx";
import EditableText from "../../../../components/global/fields/EditableText.jsx";
import SingleDuration from "../../../../components/global/fields/SingleDuration.jsx";
import EditableSelectMultiple from "../../../../components/global/fields/EditableSelectMultiple.jsx";
import { GridLogicOperator } from "@mui/x-data-grid-pro";
import { useCustomState } from "../../../../utils/useCustomState.js";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const PedidosIt = () => {
  const userStorage = localStorage.getItem("email");
  const userRole = localStorage.getItem("role");
  const apiUrl = `${backendUrl}/api/it-request/items?role=${userRole}`;
  const apiItGroup = `${backendUrl}/api/getAllGroups`;
  const [state, updateState] = useCustomState({
    status: "Tasks",
    itAllowed: [],
    groups: ["Tasks", "Done"],
    items: [],
    itemsDone: [],
    updateRows: [],
    columnsFilter: {},
    viewFilter: {
      items: [],
      quickFilterLogicOperator: GridLogicOperator.Or,
    },
    pinnedColumns: {
      left: ["__check__", "threads"],
      right: [],
    },
    selectionRow: [],
  });

  useEffect(() => {
    const fetchApi = async () => {
      const resGroups = await fetch(apiItGroup);
      const response = await resGroups.json();
      const itGroup = response.filter((group) => group.name === "IT");
      updateState({ itAllowed: itGroup[0].users });
    };
    fetchApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const filterItemsByStatus = (items, status, condition) => {
    if (condition) {
      return items?.filter((item) => item.status === status);
    }
    return items?.filter((item) => item.status !== status);
  };

  const filterItemsAndSetGroups = (items) => {
    updateState({
      itemsDone: filterItemsByStatus(items, "Done", true),
      items: filterItemsByStatus(items, "Done", false),
    });
  };

  const handleEditResponseChange = async (newValue, rowID, field) => {
    const updatedRowsNoTerminados = state.items.map((row) => {
      if (+row.id === +rowID) {
        return { ...row, [`${field}`]: newValue };
      }
      return row;
    });
    updateState({ items: updatedRowsNoTerminados });
  };

  const getGroupRows = (groupName) => {
    return groupName === "Tasks" ? state.items : state.itemsDone;
  };

  const updateBoard = (newData) => {
    const updatedRows = [...state.updateRows, newData];
    updateState({ updateRows: updatedRows });
  };

  const isUserInAllowedGroup = state.itAllowed.some((user) =>
    user.email.includes(userStorage)
  );

  const columns = [
    {
      field: "threads",
      headerName: "Thread",
      width: 70,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <Threads {...params} />;
      },
    },
    {
      field: "id",
      headerName: "Ticket ID",
      width: 80,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "name",
      headerName: "Elemento",
      width: 400,
      headerAlign: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"name"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.name}
        />
      ),
    },
    {
      field: "status",
      headerName: "Estado",
      width: 160,
      headerAlign: "center",
      cellClassName: "no-padding",
      renderCell: (params) => (
        <div style={{ width: "100%", height: "100%", cursor: "not-allowed" }}>
          <ColumnStatus
            {...params}
            selectionRow={state.selectionRow}
            handleEditResponseChange={handleEditResponseChange}
            field={"status"}
            columnPosition={280}
            disabled={
              !state.itAllowed.some((user) => user.email === userStorage)
            }
            statusOption={pedidosItStatus}
          />
        </div>
      ),
    },
    {
      field: "trafficSources",
      headerName: "Etiquetas",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableSelectMultiple
          {...params}
          field={"tags"}
          handleEditResponseChange={handleEditResponseChange}
          options={pedidosItTags.map((item) => item.title)}
          getOptionLabelFunc={(tag) => tag}
          initialValue={params.row.tags}
          label="Etiquetas"
        />
      ),
    },
    {
      field: "owner.username",
      headerName: "Pedido por",
      width: 160,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.row.owner?.username,
    },
    {
      field: "publishedAt",
      headerName: "Fecha de solicitud",
      width: 160,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <SingleDuration
          {...params}
          selectionRow={state.selectionRow}
          approvedUser={state.userStorage}
        />
      ),
    },
    isUserInAllowedGroup && {
      field: "area",
      headerName: "Area",
      width: 120,
      headerAlign: "center",
      align: "center",
      renderCell: ({ row }) => <p>{row?.owner?.role?.name || "N/A"}</p>,
    },
  ];

  return (
    <div className={container.globalContainer}>
      <Header title="" />
      <BaseBoard
        columns={columns}
        groups={state.groups}
        getGroupRows={getGroupRows}
        setGroupItems={filterItemsAndSetGroups}
        apiUrl={apiUrl}
        status={state.status}
        setStatus={(status) => updateState({ status })}
        updateBoard={updateBoard}
        updateRows={state.updateRows}
        setSelectionRow={(selectionRow) => updateState({ selectionRow })}
        selectionRow={state.selectionRow}
        setViewFilter={(viewFilter) => updateState({ viewFilter })}
        viewFilter={state.viewFilter}
        columnsFilter={state.columnsFilter}
        setColumnsFilter={(columnsFilter) => updateState({ columnsFilter })}
        pinnedColumns={state.pinnedColumns}
        setPinnedColumns={(pinnedColumns) => updateState({ pinnedColumns })}
        height={"80vh"}
        maxHeight={"80vh"}
      />
    </div>
  );
};

export default PedidosIt;
