import { React } from "react";
import Header from "../../../../components/global/Header.jsx";
import container from "../../../../styles/Global.module.css";
import { hopAlertsType } from "../../../../styles/InLineStyles/BoardStyles.js";
import BaseBoard from "../../../global/BoardTemplate/BaseBoard.jsx";
import { GridLogicOperator } from "@mui/x-data-grid-pro";
import { useCustomState } from "../../../../utils/useCustomState.js";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const HopAlerts = () => {
  const apiUrl = `${backendUrl}/api/hop/alertsKala`;

  const [state, updateState] = useCustomState({
    groups: ["Supply", "Demand"],
    status: "Alerts",
    supply: [],
    demand: [],
    columnsFilter: { id: false },
    viewFilter: {
      items: [],
      quickFilterLogicOperator: GridLogicOperator.Or,
    },
    pinnedColumns: {
      left: ["__check__", "threads"],
      right: [],
    },
  });

  const generateUniqueIds = (data) => {
    return data.map((item, index) => {
      return { ...item, id: `alert_${index}` };
    });
  };

  const filterBySide = (rows, status) => {
    const filters = {
      Supply: (item) => !item.type.includes("demand"),
      Demand: (item) => item.type.includes("demand"),
    };

    return rows.filter(filters[status]);
  };

  const filterItemsAndSetGroups = (items) => {
    const alertsWithIds = generateUniqueIds(items);

    updateState({
      supply: filterBySide(alertsWithIds, "Supply"),
      demand: filterBySide(alertsWithIds, "Demand"),
    });
  };

  const getGroupRows = (groupName) => {
    return groupName === "Supply"
      ? state.supply
      : groupName === "Demand"
      ? state.demand
      : [];
  };

  const formatNumber = (value) => {
    if (value === "" || isNaN(value)) {
      return "";
    }
    return new Intl.NumberFormat("es-ES", {
      maximumFractionDigits: 2,
    }).format(value);
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 80,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "tagID",
      headerName: "Tag ID",
      width: 80,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "tag",
      headerName: "Tag",
      width: 350,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "platform",
      headerName: "Platform",
      width: 120,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "type",
      headerName: "Type",
      width: 240,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const criticityStyle = hopAlertsType[params.row.type] || {};

        return (
          <div
            style={{
              width: "100%",
            }}
          >
            {params.row.type && (
              <p
                style={{
                  width: "100%",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  ...criticityStyle,
                }}
              >
                {params.row.type}
              </p>
            )}
          </div>
        );
      },
    },
    {
      field: "impressions",
      headerName: "Impressions",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => formatNumber(params.value),
    },
    {
      field: "supplyRequests",
      headerName: "Supply Requests",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => formatNumber(params.value),
    },
    {
      field: "demandResponses",
      headerName: "Demand Responses",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => formatNumber(params.value),
    },
    {
      field: "winRate",
      headerName: "Win Rate",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => formatNumber(params.value),
    },
    {
      field: "revenue",
      headerName: "Revenue",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => formatNumber(params.value),
    },
    {
      field: "cost",
      headerName: "Cost",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => formatNumber(params.value),
    },
  ];

  return (
    <div className={container.globalContainer}>
      <Header title="" />
      <BaseBoard
        columns={columns}
        groups={state.groups}
        getGroupRows={getGroupRows}
        setGroupItems={filterItemsAndSetGroups}
        apiUrl={apiUrl}
        status={state.status}
        setStatus={(status) => updateState({ status })}
        setViewFilter={(viewFilter) => updateState({ viewFilter })}
        viewFilter={state.viewFilter}
        columnsFilter={state.columnsFilter}
        setColumnsFilter={(columnsFilter) => updateState({ columnsFilter })}
        pinnedColumns={state.pinnedColumns}
        setPinnedColumns={(pinnedColumns) => updateState({ pinnedColumns })}
        checkboxSelection={false}
      />
    </div>
  );
};

export default HopAlerts;
