import { Autocomplete, Box, Button, Menu, TextField } from "@mui/material";
import React, { useRef } from "react";
import user from "../../../assets/icons/user-light.svg";
import { editBoardField } from "../../../utils/ApiCall";
import { useCustomState } from "../../../utils/useCustomState";

const SingleUserSelect = ({ row, users, field, handleEditResponseChange }) => {
  let currentUrl = window.location.pathname;
  const [state, updateState] = useCustomState({
    anchorEl: null,
    open: false,
    windowWidth: window.innerWidth,
    selectedUser:
      row?.responsible || row?.commercial || row?.owner
        ? { username: row[field]?.username, id: row[field]?.id }
        : null,
  });

  const isMobile = state.windowWidth <= 600;
  const cellRef = useRef(null);

  const handleClose = () => {
    updateState({ anchorEl: null, open: false });
  };

  const handleClick = (event) => {
    updateState({ anchorEl: event.currentTarget, open: true });
  };

  const handleAutocompleteChange = (_, newValue) => {
    updateState({ selectedUser: newValue });
  };

  const submitValue = state.selectedUser ? state.selectedUser.id : null;
  let renderValue;
  if (field === "responsible" || field === "commercial") {
    renderValue = state.selectedUser ? state.selectedUser.username : null;
  } else if (field === "owner" && currentUrl === "/master-branding") {
    renderValue = row[field]?.username;
  } else if (field === "comercial") {
    renderValue = state.selectedUser ? state.selectedUser.username : null;
  }

  const userId = users.find((u) => u.id === submitValue) || null;

  const cellStyle = {
    position: "relative",
  };

  const menuStyle = {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    width: 500,
    height: isMobile ? 600 : "auto",
    overflowY: isMobile ? "scroll" : "",
    paddingRight: isMobile ? "20px" : "0px",
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: "49%",
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  };

  const menuProps = {
    anchorEl: state.anchorEl,
    id: "account-menu",
    open: state.open,
    onClose: handleClose,
    PaperProps: {
      elevation: 0,
      sx: {
        ...menuStyle,
        marginLeft: isMobile ? "0px" : "190px",
      },
    },
    transformOrigin: { horizontal: "right", vertical: "top" },
    anchorOrigin: { horizontal: "right", vertical: "bottom" },
  };

  const handleSubmit = () => {
    editBoardField(currentUrl, [row.id], field, submitValue);
    handleEditResponseChange(state.selectedUser, row.id, field);
    handleClose();
  };

  return (
    <Box>
      <p
        onClick={handleClick}
        ref={cellRef}
        style={cellStyle}
        className="cellStyle"
      >
        {!state.selectedUser || row[field] === null ? (
          <div
            style={{
              width: "120px",
              padding: "0 10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={user} alt="Usuario" />
          </div>
        ) : (
          <div
            style={{
              width: "120px",
              padding: "0 10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span>{renderValue}</span>
          </div>
        )}
      </p>
      <Menu {...menuProps}>
        {" "}
        <Autocomplete
          size="small"
          disablePortal
          id="single-user-select"
          options={users}
          getOptionLabel={(user) => user?.username}
          sx={{
            width: "100%",
          }}
          onChange={handleAutocompleteChange}
          value={userId}
          renderInput={(params) => (
            <TextField {...params} label="Usuario" variant="standard" />
          )}
        />
        <div
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <Button sx={{ marginTop: "20px" }} onClick={handleSubmit}>
            Guardar
          </Button>
        </div>
      </Menu>
    </Box>
  );
};

export default SingleUserSelect;
