/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import container from "../styles/Global.module.css";
import HomeDashBoard from "../pages/BusinessUnit/aluna/dashboards/HomeDashBoard";
import { useCustomState } from "../utils/useCustomState";

const Home = () => {
  const [state, updateState] = useCustomState({
    itAllowed: null,
    allowDashboard: false,
  });

  useEffect(() => {
    updateState({ itAllowed: localStorage?.getItem("role") });
    if (
      state.itAllowed === "Authenticated" ||
      state.itAllowed === "aluna-management"
    ) {
      updateState({ allowDashboard: true });
    }
  }, [state.itAllowed]);

  return (
    <>
      {state.allowDashboard ? (
        <HomeDashBoard />
      ) : (
        <div className={container.homeContainer}>
          <div className={container.homeImage}>
            <img src="./ionvo.png" alt="aluna" height="200px" width="auto" />
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
