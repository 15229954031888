import * as React from "react";
import {
  DataGridPro,
  useGridApiContext,
  useGridSelector,
  gridFilteredDescendantCountLookupSelector,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
import {
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Autocomplete,
  Checkbox,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { fetchData } from "../../../../utils/ApiCall";
import { useEffect } from "react";
import CustomToolBar from "../../../../components/global/CustomToolBar";
import { useMemo } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import { useCustomState } from "../../../../utils/useCustomState";

export const isNavigationKey = (key) =>
  key === "Home" ||
  key === "End" ||
  key.indexOf("Arrow") === 0 ||
  key.indexOf("Page") === 0 ||
  key === " ";

function CustomGridTreeDataGroupingCell(props) {
  const { id, field, rowNode } = props;
  const apiRef = useGridApiContext();
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector
  );
  const filteredDescendantCount =
    filteredDescendantCountLookup[rowNode.id] ?? 0;

  const handleClick = (event) => {
    if (rowNode.type !== "group") {
      return;
    }

    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  return (
    <Box sx={{ ml: rowNode.depth * 4 }}>
      <div>
        {filteredDescendantCount > 0 ? (
          <span
            onClick={handleClick}
            tabIndex={-1}
            style={{ cursor: "pointer" }}
          >
            {props?.row?.hierarchy[0]} TOTAL
          </span>
        ) : rowNode?.depth === 0 ? (
          <span></span>
        ) : (
          <span />
        )}
      </div>
    </Box>
  );
}

const groupingColDef = {
  headerName: "Dia",
  width: 100,
  renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
};

const getRowClassName = (params) => {
  const row = params.row;

  if (row.offerTitle === "TOTAL") {
    return "total-row";
  }
  if (row?.hierarchy?.length === 1) {
    return "highlighted-row";
  }

  return "";
};

const generateUniqueId = () => {
  const min = 10;
  const max = 1000;
  return Math.floor(Math.random() * (max - min) + min);
};

const getTreeDataPath = (row) => {
  return row.hierarchy;
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const names = [
  { value: "PAUSED" },
  { value: "PAUSED - CAP REACHED" },
  { value: "ACTIVE" },
  { value: "RUNNING" },
  { value: "INACTIVE" },
];

const theme = createTheme({
  palette: {
    primary: {
      main: "#0971f1",
      darker: "#053e85",
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
  },
});

const getTodaysDay = () => {
  const date = new Date().toISOString();
  const first = "01";
  const firstOfMonth = date.slice(0, 8).concat(first);
  const today = date.slice(0, 10);

  return { firstOfMonth, today };
};

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export default function TreeDataCustomGroupingColumn() {
  const apiService = `${backendUrl}/api/offer-service`;
  const apiRef = useGridApiRef();
  const dates = getTodaysDay();

  const [state, updateState] = useCustomState({
    offers: [],
    searchText: "",
    searchResult: [],
    isSearching: false,
    isLoadingRows: false,
    searchByOfferID: null,
    searchByStatus: false,
    newArray: [],
    filterOn: false,
    offerStatus: "",
    isListOpen: false,
    isTextFieldClicked: false,
    enableButton: false,
    offerID: "",
    dateFrom: dates.firstOfMonth,
    dateTo: dates.today,
  });

  //Funcion para buscar offers en el autocomplete
  const searchOffers = async (query, searchParam) => {
    const url = `${apiService}/autocompleteOffersMasters?${searchParam}=${query}`;
    const resOffers = await fetchData(url);
    updateState({ searchResult: resOffers });
  };

  useEffect(() => {
    if (state.searchText && !state.offerID) {
      if (state.searchByOfferID) {
        searchOffers(state.searchText, "offerID");
      } else {
        searchOffers(state.searchText, "offerTitle");
      }
      updateState({ isSearching: true });
    } else if (
      (state.offerStatus || !state.isTextFieldClicked) &&
      !state.offerID
    ) {
      searchOffers(state.offerStatus?.value, "offerStatus");
      updateState({
        isSearching: true,
        isListOpen: true,
      });
    } else {
      updateState({
        searchResult: [],
        isSearching: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.searchText,
    state.searchByOfferID,
    state.searchByStatus,
    state.offerStatus,
    state.isTextFieldClicked,
  ]);

  //Funcion para seguir el value en el autocomplete
  const handleSearchTextChange = (e) => {
    updateState({
      searchText: e.target.value,
      searchByOfferID: !isNaN(e.target.value),
      offerStatus: "",
      isTextFieldClicked: false,
    });
  };

  //Funcion para cargar la offer seleccionada
  const handleLiClick = async (value) => {
    if (value) {
      updateState({
        offerID: value.offerID,
        searchText: value.offerTitle,
        isTextFieldClicked: false,
        isListOpen: false,
        enableButton: true,
      });
    }
  };

  const handleSubmit = async () => {
    if (!isNaN(state.offerID)) {
      updateState({
        enableButton: false,
        offers: [],
        isLoadingRows: true,
      });

      const formattedDateFrom = state.dateFrom
        ? dayjs(state.dateFrom).format("YYYY-MM-DD")
        : "";
      const formattedDateTo = state.dateTo
        ? dayjs(state.dateTo).format("YYYY-MM-DD")
        : "";

      const seguimientoUrl = `${apiService}/dailyCampaignTracking?offerID=${state.offerID}&dateFrom=${formattedDateFrom}&dateTo=${formattedDateTo}`;

      const seguimientoData = await fetchData(seguimientoUrl);

      const modifiedOffers = seguimientoData.map((offer) => {
        if (offer.offerID) {
          return {
            ...offer,
            clicks: offer.totalClicks,
            pending: offer.totalPending,
            declined: offer.totalDecline,
            cr: offer.totalCr,
            revenue: offer.totalRevenue,
            payouts: offer.totalPayout,
            profit: offer.totalProfit,
            cumplimientoCampana: `${
              (offer.totalPending * 100) / offer.totalCap
            }%`,
          };
        } else {
          return offer;
        }
      });

      updateState({
        isLoadingRows: false,
        offers: modifiedOffers,
        searchResult: [],
        searchText: "",
        offerStatus: "",
        isSearching: true,
        isListOpen: false,
        dateTo: getTodaysDay().today,
        dateFrom: getTodaysDay().firstOfMonth,
        offerID: "",
      });
    }
  };
  //Funcion para saber que se esta filtrando
  const handleFilterModel = (filterModel) => {
    if (filterModel.items.length > 0 && filterModel.items[0].value) {
      updateState({ filterOn: true });
    } else {
      updateState({ filterOn: false });
    }

    let offers;
    switch (filterModel?.items[0]?.field) {
      case "affiliate":
        offers = updatedOffers.filter((aff) =>
          aff?.hierarchy[1]
            ?.toLowerCase()
            .includes(filterModel?.items[0]?.value?.toLowerCase())
        );
        updateState({ newArray: offers });
        break;
      default:
        break;
    }
  };

  //Funcion para calcular la suma de un field
  const calculateFieldSum = (fieldName) => {
    if (state.newArray.length > 0 && state.filterOn) {
      return state.newArray
        .filter((offer) => offer.hierarchy.length === 2)
        .reduce((sum, offer) => sum + (offer[fieldName] || 0), 0);
    } else {
      return state.offers
        .filter((offer) => offer.hierarchy.length === 2)
        .reduce((sum, offer) => sum + (offer[fieldName] || 0), 0);
    }
  };

  //Funcion para redondear un decimal
  const roundDecimal = (numero) => {
    const numeroRedondeado = Math.round(numero * 10) / 10;
    return numeroRedondeado;
  };

  const totalPendingSum = calculateFieldSum("pending");
  const totalClicksSum = calculateFieldSum("clicks");
  const totalDeclinedSum = calculateFieldSum("declined");
  const totalRevenueSum = `$${roundDecimal(calculateFieldSum("revenue"))}`;
  const totalPayoutSum = `$${roundDecimal(calculateFieldSum("payout"))}`;
  const totalProfitSum = `$${roundDecimal(calculateFieldSum("profit"))}`;
  const totalCrSum = roundDecimal((totalPendingSum / totalClicksSum) * 100);
  const totalCap = state.offers[0]?.totalCap; // es para toda la offer igual
  const payoutAffiliate = state.offers[0]?.totalPayoutAssigned; // es para toda la offer igual
  const revenueAffiliate = state.offers[0]?.totalRevenueAssigned; // es para toda la offer igual

  const uniqueId = useMemo(() => generateUniqueId(), []);

  //Row con el total
  const totalRow = {
    offerTitle: "TOTAL",
    id: uniqueId,
    hierarchy: [uniqueId],
    manager: state.filterOn ? state.newArray[0]?.manager : "",
    affiliate: state.filterOn ? state.newArray[0]?.hierarchy[1] : "",
    pending: totalPendingSum,
    clicks: totalClicksSum,
    declined: totalDeclinedSum,
    revenue: totalRevenueSum,
    payouts: totalPayoutSum,
    profit: totalProfitSum,
    cr: isNaN(totalCrSum) ? 0 : totalCrSum,
    totalCap,
    payoutAffiliate,
    revenueAffiliate,
  };

  const updatedOffers = [...state.offers, totalRow];

  const formatCumplimiento = (cumplimiento) => {
    if (isNaN(cumplimiento) || !isFinite(cumplimiento)) {
      return "0%";
    }
    return `${roundDecimal(cumplimiento)}%`;
  };

  const cumplimientoCampana = formatCumplimiento(
    (totalPendingSum * 100) / totalCap
  );

  const handleChange = (event, newValue) => {
    updateState({
      offerStatus: newValue,
      searchByStatus: true,
      searchText: "",
    });
  };

  const columns = [
    { field: "offerID", headerName: "Offer ID", width: 80 },
    { field: "offerTitle", headerName: "Offer", width: 120 },
    { field: "advertiser", headerName: "Advertiser", width: 120 },
    {
      field: "timeframe",
      headerName: "Timeframe",
      width: 80,
    },
    {
      field: "conversionType",
      headerName: "Conversion Type",
      width: 120,
    },
    {
      field: "vertical",
      headerName: "Vertical",
      width: 80,
    },
    {
      field: "country",
      headerName: "Geo",
      width: 60,
    },
    {
      field: "duration",
      headerName: "Duration",
      width: 150,
    },
    {
      field: "targeting",
      headerName: "Targeting",
      width: 150,
    },
    {
      field: "programManager",
      headerName: "Program Manager",
      width: 120,
    },
    { field: "status", headerName: "Estado", width: 100 },
    {
      field: "affiliate",
      headerName: "Afiliado",
      width: 120,

      valueGetter: (params) => {
        const hierarchy = params.row.hierarchy;
        const rowNode = params.rowNode;
        if (
          params.row.hierarchy.length === 1 &&
          params.row.offerTitle === "TOTAL"
        ) {
          return totalRow?.affiliate;
        }
        if (rowNode.depth === 0) {
          return "";
        }

        return hierarchy[hierarchy.length - 1];
      },
    },
    {
      field: "clicks",
      headerName: "Clicks",
      width: 80,
      valueGetter: ({ row }) => {
        if (row.hierarchy.length === 1 && row.offerTitle === "TOTAL") {
          return totalClicksSum;
        }
        if (row.hierarchy.length === 1 && state.filterOn) {
          const filterClicks = state.newArray.filter((offer) =>
            offer.hierarchy.includes(row.hierarchy[0])
          );
          const clicksSum = filterClicks.reduce(
            (sum, offer) => sum + (offer.clicks || 0),
            0
          );
          return clicksSum;
        }

        if (row.hierarchy.length === 2) {
          return row.clicks;
        }
        return row.totalClicks;
      },
    },
    {
      field: "trafficback",
      headerName: "Trafficback",
      width: 80,
      valueGetter: (params) => {
        const rowNode = params.rowNode;

        if (rowNode.depth === 0) {
          return params.row.totalTrafficback;
        }
        if (params.row.hierarchy.length === 2) {
          return params.row.trafficback;
        }
      },
    },
    {
      field: "pending",
      headerName: "Pending",
      width: 80,
      valueGetter: ({ row }) => {
        if (row.hierarchy.length === 1 && row.offerTitle === "TOTAL") {
          return totalPendingSum;
        }
        if (row.hierarchy.length === 1 && state.filterOn) {
          const filterPending = state.newArray.filter((offer) =>
            offer.hierarchy.includes(row.hierarchy[0])
          );
          const pendingSum = filterPending.reduce(
            (sum, offer) => sum + (offer.pending || 0),
            0
          );
          return pendingSum;
        }

        if (row.hierarchy.length === 2) {
          return row.pending;
        }
        return row.totalPending;
      },
    },
    {
      field: "declined",
      headerName: "Declined",
      width: 80,
      valueGetter: ({ row }) => {
        if (row.hierarchy.length === 1 && row.offerTitle === "TOTAL") {
          return totalDeclinedSum;
        }
        if (row.hierarchy.length === 1 && state.filterOn) {
          const filterDeclined = state.newArray.filter((offer) =>
            offer.hierarchy.includes(row.hierarchy[0])
          );
          const declinedSum = filterDeclined.reduce(
            (sum, offer) => sum + (offer.declined || 0),
            0
          );
          return declinedSum;
        }

        if (row.hierarchy.length === 2) {
          return row.declined;
        }
        return row.totalDecline;
      },
    },
    {
      field: "cr",
      headerName: "CR",
      width: 80,
      renderCell: (params) => {
        const cr = roundDecimal(params.row?.cr);
        if (
          params.row.hierarchy.length === 1 &&
          params.row.offerTitle === "TOTAL"
        ) {
          return <p>{cr ? `${roundDecimal(cr)}%` : "0%"}</p>;
        }
        if (params.row.hierarchy.length === 1 && state.filterOn) {
          const filteredCr = state.newArray.filter((offer) =>
            offer.hierarchy.includes(params.row.hierarchy[0])
          );

          const clicks = filteredCr[0]?.clicks;
          const pending = filteredCr[0]?.pending;
          const totalCr = (pending / clicks) * 100;

          return <p>{totalCr ? `${roundDecimal(totalCr)}%` : "0%"}</p>;
        }
        if (params.row.hierarchy.length === 2) {
          return <p>{roundDecimal(params.row.cr * 100)}%</p>;
        }

        const totalCr = params.row.totalCr;

        return (
          <p>
            {totalCr && params.row.hierarchy.length === 1
              ? `${roundDecimal(totalCr)}%`
              : totalCr === 0 && params.row.hierarchy.length === 1
              ? "0%"
              : ""}
          </p>
        );
      },
    },
    {
      field: "affiliateCap",
      headerName: "Cap asignado por Afiliado",
      width: 120,
      valueGetter: (params) => {
        const rowNode = params.rowNode;

        if (rowNode.depth === 0) {
          return null;
        }

        if (params.row.hierarchy.length === 2) {
          return params.row.cap.value;
        }
      },
    },
    {
      field: "totalCap",
      headerName: "Cap Total Campaña",
      width: 120,
    },
    {
      field: "compliance",
      headerName: "Porcentaje de Cumplimiento",
      width: 120,

      valueGetter: (params) => {
        const rowNode = params.rowNode;

        if (rowNode.depth === 0) {
          return params.row.totalCompliance
            ? `${params.row.totalCompliance}%`
            : null;
        }

        if (params.row.hierarchy.length === 2) {
          return params.row.compliance ? `${params.row.compliance}%` : null;
        }
      },
    },
    {
      field: "revenue",
      headerName: "Revenue",
      width: 80,
      valueGetter: (params) => {
        const revenue = `$${roundDecimal(params?.row?.revenue)}`;

        if (
          params.row.hierarchy.length === 1 &&
          params.row.offerTitle === "TOTAL"
        ) {
          return totalRevenueSum;
        }

        if (params.row.hierarchy.length === 1 && state.filterOn) {
          const filterRevenue = state.newArray.filter((offer) =>
            offer.hierarchy.includes(params.row.hierarchy[0])
          );
          const revenueSum = filterRevenue.reduce(
            (sum, offer) => sum + (offer.revenue || 0),
            0
          );
          return `$${revenueSum}`;
        }

        if (params.row.hierarchy.length === 2) {
          return revenue;
        }

        return `$${params.row.totalRevenue}`;
      },
    },
    {
      field: "payouts",
      headerName: "Payout",
      width: 80,
      valueGetter: (params) => {
        const payout = `$${roundDecimal(params?.row?.payouts)}`;

        if (
          params.row.hierarchy.length === 1 &&
          params.row.offerTitle === "TOTAL"
        ) {
          return totalPayoutSum;
        }

        if (params.row.hierarchy.length === 1 && state.filterOn) {
          const filterPayout = state.newArray.filter((offer) =>
            offer.hierarchy.includes(params.row.hierarchy[0])
          );
          const payoutssum = filterPayout.reduce(
            (sum, offer) => sum + (offer.payouts || 0),
            0
          );
          return `$${payoutssum}`;
        }

        if (params.row.hierarchy.length === 2) {
          return payout;
        }

        return `$${params.row.totalPayout}`;
      },
    },
    {
      field: "profit",
      headerName: "Profit",
      width: 80,
      valueGetter: (params) => {
        const profit = `$${roundDecimal(params?.row?.profit)}`;

        if (
          params.row.hierarchy.length === 1 &&
          params.row.offerTitle === "TOTAL"
        ) {
          return totalProfitSum;
        }

        if (params.row.hierarchy.length === 1 && state.filterOn) {
          const filterProfit = state.newArray.filter((offer) =>
            offer.hierarchy.includes(params.row.hierarchy[0])
          );
          const profitSum = filterProfit.reduce(
            (sum, offer) => sum + (offer.profit || 0),
            0
          );
          return `$${profitSum}`;
        }

        if (params.row.hierarchy.length === 2) {
          return profit;
        }

        return `$${params.row.totalProfit}`;
      },
    },
    {
      field: "payoutAffiliate",
      headerName: "Payout Affiliate",
      width: 120,
      renderCell: (params) => {
        const rowNode = params.rowNode;
        if (
          params.row.hierarchy.length === 1 &&
          params.row.offerTitle === "TOTAL"
        ) {
          return payoutAffiliate;
        }
        if (rowNode.depth === 0) {
          return null;
        }

        return params.row.payoutAffiliate
          ? `$${params.row.payoutAffiliate}`
          : null;
      },
    },
    {
      field: "revenueAffiliate",
      headerName: "Revenue Affiliate",
      width: 120,
      renderCell: (params) => {
        const rowNode = params.rowNode;
        if (
          params.row.hierarchy.length === 1 &&
          params.row.offerTitle === "TOTAL"
        ) {
          return revenueAffiliate;
        }
        if (rowNode.depth === 0) {
          return null;
        }

        return params.row.revenueAffiliate
          ? `$${params.row.revenueAffiliate}`
          : null;
      },
    },
  ];

  return (
    <div style={{ height: "100%", width: "100%", backgroundColor: "#f8f7ff" }}>
      <Box sx={{ padding: "15px 30px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", marginBottom: "12px" }}>
              <div style={{ marginRight: "15px" }}>
                <TextField
                  type="text"
                  label="Offer ID / Offer Title"
                  value={state.searchText}
                  disabled={state.isLoadingRows}
                  onChange={handleSearchTextChange}
                  onClick={() => updateState({ isTextFieldClicked: true })}
                  sx={{
                    width: "350px",
                    backgroundColor: "white",
                    position: "relative",
                  }}
                />

                {state.isSearching &&
                  state.searchResult.length === 0 &&
                  state.searchText !== "" && (
                    <List
                      component="ul"
                      className="autocomplete-ul"
                      sx={{
                        position: "absolute",
                        width: "350px",
                        zIndex: "1000",
                      }}
                    >
                      <ListItem className="autocomplete-li" disabled={true}>
                        <ListItemText primary="Offer no encontrada" />
                      </ListItem>
                    </List>
                  )}

                {state.isSearching &&
                  state.isListOpen &&
                  state.searchResult.length > 0 && (
                    <List
                      component="ul"
                      className="autocomplete-ul"
                      sx={{
                        width: "350px",
                        zIndex: "1000",
                        position: "absolute",
                      }}
                    >
                      {state.isSearching &&
                        state.isListOpen &&
                        state.searchResult
                          .filter((item) => item.offerStatus)
                          .map((result) => (
                            <ListItem
                              key={result.offerID}
                              onClick={() => handleLiClick(result)}
                              className="autocomplete-li"
                              disabled={state.isLoadingRows}
                              sx={{
                                backgroundColor:
                                  result.offerStatus === "ACTIVE"
                                    ? "var(--greenYellow)"
                                    : result.offerStatus === "PAUSED"
                                    ? "var(--paused)"
                                    : result.offerStatus ===
                                      "PAUSED - CAP REACHED"
                                    ? "var(--greenDark)"
                                    : result.offerStatus === "RUNNING"
                                    ? "var(--violet)"
                                    : result.offerStatus === "INACTIVE"
                                    ? "rgb(226, 68, 92)"
                                    : "var(--gray)",
                                color: "white",
                                fontWeight: "700",
                                margin: "5px 0px",
                                "&:hover": {
                                  backgroundColor: "var(--blackLow)",
                                },
                              }}
                            >
                              <ListItemText primary={result.offerTitle} />
                            </ListItem>
                          ))}
                    </List>
                  )}
              </div>

              <Autocomplete
                id="checkboxes-tags-demo"
                options={names}
                disableCloseOnSelect
                getOptionLabel={(option) => option?.value ?? ""}
                defaultValue=""
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                      size="small"
                    />
                    {option.value ?? ""}
                  </li>
                )}
                value={state.offerStatus ?? null}
                onChange={handleChange}
                style={{ width: 350 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Offer Status"
                    sx={{ backgroundColor: "white" }}
                  />
                )}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginBottom: "12px",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateRangePicker
                  localeText={{
                    start: `${state.dateFrom}`,
                    end: `${state.dateTo}`,
                  }}
                  sx={{ backgroundColor: "white", marginRight: "10px" }}
                  onChange={(newValue) => {
                    if (newValue[0]) updateState({ dateFrom: newValue[0] });
                    if (newValue[1]) updateState({ dateTo: newValue[1] });
                  }}
                  disableFuture
                />
              </LocalizationProvider>
              <ThemeProvider theme={theme}>
                <Button
                  variant="outlined"
                  onClick={handleSubmit}
                  disabled={!state.enableButton}
                >
                  Generar
                </Button>
              </ThemeProvider>
            </div>
          </div>

          <TableContainer
            sx={{ width: "350px", height: "107px", backgroundColor: "white" }}
          >
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ fontWeight: "600" }}>CAP</TableCell>
                  <TableCell>100%</TableCell>
                  <TableCell>{updatedOffers[0]?.totalCap ?? 0}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>{cumplimientoCampana}</TableCell>
                  <TableCell>{roundDecimal(totalPendingSum) ?? 0}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <DataGridPro
          slots={{
            toolbar: () => <CustomToolBar apiRef={apiRef} />,
            loadingOverlay: () => <LinearProgress />,
          }}
          loading={state.isLoadingRows ? true : false}
          treeData
          rows={updatedOffers}
          columns={columns}
          getTreeDataPath={getTreeDataPath}
          groupingColDef={groupingColDef}
          rowHeight={30}
          defaultGroupingExpansionDepth={-1}
          getRowClassName={getRowClassName}
          sx={{ backgroundColor: "white" }}
          getRowId={(row) =>
            row.hierarchy.length === 1 ? row.id : updatedOffers.indexOf(row)
          }
          onFilterModelChange={handleFilterModel}
          apiRef={apiRef}
        />
      </Box>
    </div>
  );
}
